import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@mui/material';
import { EntityService, PlanService } from '../../../../services';
import { showErrorMessage, showSuccessMessage } from '../../../../managers/utility';
import CloseIcon from '@mui/icons-material/Close';

const CorporateModal = ({ isOpen, onCloseModal, onAdd, getCorporateData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    numberOfLicense: '',
    selectedPlan: '',
    entityType: '',
    showReports: false,
    isTrial: false,
    plans: [],
    errors: {},
  });

  console.log(formData);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    const plans = await PlanService.getAllPlans({ isDeleted: false });
    setFormData(prevData => ({ ...prevData, plans }));
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: '' },
    }));
  };

  const handleReportAccess = e => {
    console.log('HERE');

    setFormData(prevData => ({ ...prevData, showReports: e.target.checked }));
  };

  const handleIsTrial = e => {
    console.log('HERE');
    setFormData(prevData => ({ ...prevData, isTrial: e.target.checked }));
  };

  const handleAddCorporate = async () => {
    const { name, email,password, remainingSession, registrationNumber, numberOfLicense, entityType, selectedPlan, showReports, isTrial } = formData;
    let valid = true;
    const errors = {};

    if (!name.trim()) {
      errors.name = 'Name is required';
      valid = false;
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
      valid = false;
    }

    if (entityType === 'CORPORATE') {
      if (!numberOfLicense) {
        errors.numberOfLicense = 'Number of Licenses is required';
        valid = false;
      } else if (parseInt(numberOfLicense) <= 0) {
        errors.numberOfLicense = 'Number of Licenses must be greater than 0';
        valid = false;
      }

      if (!selectedPlan) {
        errors.selectedPlan = 'Please select a Plan';
        valid = false;
      }
    }

    if (valid) {
      try {
        const response = await EntityService.addEntityUser({
          name,
          email,
          numberOfLicense: parseInt(numberOfLicense) || 0,
          type: entityType,
          planId: selectedPlan || null,
          showReports,
          isTrial,
          password,
          remainingSession,
          registrationNumber: registrationNumber || 'NOT_AVAILABLE',
        });
        if (response._id) {
          showSuccessMessage('User Added Successfully, Please Check Registered Mail');
          onCloseModal();
          getCorporateData();
          setFormData({
            ...formData,
            name: '',
            email: '',
            numberOfLicense: '',
            selectedPlan: '',
            entityType: '',
          });
        } else {
          showErrorMessage(response.error || response.message || 'Unable To Add');
        }
      } catch (error) {
        console.error('Error adding corporate user:', error);
        showErrorMessage(error.message);
      }
    } else {
      setFormData(prevData => ({ ...prevData, errors }));
    }
  };

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onCloseEntity = () => {
    setFormData({
      name: '',
      email: '',
      numberOfLicense: '',
      selectedPlan: '',
      entityType: '',
      showReports: false,
      plans: [],
      errors: {},
    });
    onCloseModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseEntity}
      BackdropProps={{
        onClick: onCloseEntity,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'self-end',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Add Entity
          </Typography>
          <IconButton onClick={onCloseEntity}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            paddingLeft: 5,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Name"
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
                margin="normal"
                name="name"
                error={!!formData.errors.name}
                helperText={formData.errors.name}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Registration Number"
                fullWidth
                value={formData.registrationNumber}
                onChange={handleInputChange}
                margin="normal"
                name="registrationNumber"
                error={!!formData.errors.registrationNumber}
                helperText={formData.errors.registrationNumber}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Email"
                fullWidth
                value={formData.email}
                onChange={handleInputChange}
                margin="normal"
                name="email"
                error={!!formData.errors.email}
                helperText={formData.errors.email}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            onChange={handleIsTrial}
            control={<Checkbox checked={formData.isTrial} onChange={handleIsTrial} />}
            label="Trial"
          />
          <FormControlLabel
            control={<Checkbox checked={formData.showReports} onChange={handleReportAccess} />}
            label="User's Report Access"
          />
        </div>
        <FormControl fullWidth style={{ marginTop: 16 }}>
          <InputLabel>Type</InputLabel>
          <Select
            name="entityType"
            value={formData.entityType}
            label="Type"
            onChange={handleInputChange}
            error={!!formData.errors.entityType}
          >
            <MenuItem value="">Select Type</MenuItem>
            <MenuItem value={'CORPORATE'}>Corporate</MenuItem>
            <MenuItem value={'PSYCHOLOGIST'}>Mental Health Professional</MenuItem>
            <MenuItem value={'HOSPITAL'}>Hospital</MenuItem>
            <MenuItem value={'INTEGRATION'}>Integration</MenuItem>
          </Select>
          {formData.errors.entityType && <FormHelperText error>{formData.errors.entityType}</FormHelperText>}
        </FormControl>
        {formData.entityType === 'CORPORATE' && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Number of Licenses"
                  fullWidth
                  type="number"
                  value={formData.numberOfLicense}
                  onChange={handleInputChange}
                  margin="normal"
                  name="numberOfLicense"
                  error={!!formData.errors.numberOfLicense}
                  helperText={formData.errors.numberOfLicense}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: 16 }}>
                  <InputLabel>Plan</InputLabel>
                  <Select
                    name="selectedPlan"
                    value={formData.selectedPlan}
                    label="Type"
                    onChange={handleInputChange}
                    error={!!formData.errors.selectedPlan}
                  >
                    {formData.plans.map(plan => (
                      <MenuItem key={plan._id} value={plan._id}>
                        {plan.plansType}
                      </MenuItem>
                    ))}
                  </Select>
                  {formData.errors.selectedPlan && <FormHelperText error>{formData.errors.selectedPlan}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {formData.entityType === 'INTEGRATION' && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Sessions"
                fullWidth
                value={formData.remainingSession}
                onChange={handleInputChange}
                margin="normal"
                name="remainingSession"
                error={!!formData.errors.remainingSession}
                helperText={formData.errors.remainingSession}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Password"
                fullWidth
                value={formData.password}
                onChange={handleInputChange}
                margin="normal"
                name="password"
                error={!!formData.errors.password}
                helperText={formData.errors.password}
              />
            </Grid>
          </Grid>
        )}
        <Button variant="contained" fullWidth style={{ marginTop: 20 }} color="primary" onClick={handleAddCorporate}>
          Add Entity
        </Button>
      </Box>
    </Modal>
  );
};

export default CorporateModal;
